import Api from './Api';

const END_POINT = 'production';

export default {
    async get_all(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'production'
                else
                    url = END_POINT
            } else {
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(url, request);
    },

    async getKardexBySupply(supply_id){
        console.log(supply_id);
        let request = {
            params: {
                'supply_id' : supply_id
            }
        }
        const url = 'kardex-by-supply'
        //const response = await axios.get(url, supply)
        return Api.get(url, request);
    },

    async saveNewInventory(new_stocks){
        const url = 'kardex-save-inventory';
        return Api.post(url, new_stocks);
    },

    async saveDomesticConsumption(new_stocks){
        const url = 'kardex-save-domestic-consumption';
        return Api.post(url, new_stocks);
    },

    async saveDecrease(new_stocks){
        const url = 'kardex-save-decrease';
        return Api.post(url, new_stocks);
    },

    async listRecetas (filters) {
        let request = {
            params: filters
        }
        const url = 'recipes';
        return await Api.get(url, request);
    },

    async saveReceta(data) {
        const url = 'recipes';
        return await Api.post(url, data);
    },

    async updateReceta(id, data) {
        const url = 'recipes/'+id;
        return await Api.patch(url, data);
    }
}
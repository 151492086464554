<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                            {{ item.text.toUpperCase() }}
                        </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Almacen de Insumos</span>
                    <br />
                    <storehouse-breadcrumb></storehouse-breadcrumb>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <!--<v-btn
                        v-if="this.$hasPermision('almacenes.principal')"
                        dark
                        color="#023145"
                        width="140"
                        class="float-right"
                        @click="handleMainStore"
                    >
                        Principal
                    </v-btn>-->
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="4" class="pt-1">
                    <v-text-field
                        v-model="search_product"
                        label="Buscar"
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        solo
                        @keydown="handleInputSearchByEnter"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="5" class="pt-1">
                    <v-btn class="w-btn-search" height="40" @click="handleSearch">
                        Buscar
                    </v-btn>
                </v-col>
                <!--<v-col cols="3" class="pt-1">
                    <v-btn
                        v-if="this.$hasPermision('productos.filters')"
                        dark
                        color="#023145"
                        outlined
                        width="140"
                        class="float-right"
                        @click="handleShowFilters"
                    >
                        <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
                        Ver Filtros
                    </v-btn>
                    <v-btn
                        dark
                        color="green"
                        class="float-right mr-2"
                        @click="handleDownload"
                    >
                        <v-icon class="mr-1" size="16">fas fa-download</v-icon>
                        Descargar
                    </v-btn>
                </v-col>-->
                <v-col cols="3" class="pt-1">
                    <v-menu rounded="0" offset-y>
                        <template v-slot:activator="{ attrs, on }">
                        <v-btn
                            color="blue"
                            class="white--text float-right mr-2"
                            v-bind="attrs"
                            v-on="on"
                            :disabled="showButtonSave"
                        >
                            Guardar Movimiento
                        </v-btn>
                        </template>

                        <v-list>
                       <v-list-item link>
                            <v-list-item-title @click="handleSaveStock('new_stock')"
                            >Nuevo Stock
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item link>
                            <v-list-item-title
                            @click="handleSaveStock('increase_stock')"
                            >Aumentar Stock
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item link>
                            <v-list-item-title
                            @click="handleSaveStock('domestic_consumption')"
                            >Consumo Interno
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item link>
                            <v-list-item-title @click="handleSaveStock('decrease')"
                            >Merma
                            </v-list-item-title>
                        </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>

            <v-row v-if="main_storehouse == true">
                <v-col cols="12" class="pt-1 pb-1">
                    <v-card>
                        <v-data-table
                            v-model="selectedRows"
                            :headers="headers_main"
                            :items="inventories"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="50vh"
                            :single-select="true"
                            :loading="loading"
                            :options.sync="options"
                            :server-items-length="paginated.total"
                            loading-text="Loading... Please wait"
                            
                        >
                        <template v-slot:top>
                            <span
                                class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table"
                            >
                                Inventario
                            </span>
                            <v-divider></v-divider>
                        </template>

                        <template v-slot:item.description="{ item }">
                            <span>
                            {{ item.description }}
                            </span>
                        </template>

                        <template v-slot:item.category_name="{ item }">
                            <span>
                            {{ item.category_name }}
                            </span>
                        </template>

                        <template v-slot:item.stock="{ item }">
                            <span
                                
                                >
                                    {{ item.stock }}
                            </span>
                            <!--<span
                                v-else-if="
                                    item.stock 
                                "
                                class="label-minimum_stock"
                                >
                                    <v-icon color="red" class="mr-2" size="16">
                                        fas fa-exclamation-triangle
                                    </v-icon>
                                    {{ item.stock }}
                            </span>
                            <span v-else class="label-minimum_stock">
                                <v-icon color="red" class="mr-2" size="16">
                                    fas fa-exclamation-triangle
                                </v-icon>
                                {{item.stock}}
                            </span>-->
                        </template>

                        <template v-slot:item.cost="{ item }">
                            <span v-if="item.cost">
                            {{ (item.stock * parseFloat(item.cost)) | currency("S/") }}
                            </span>
                        </template>

                        <template v-slot:item.new_stock="{ item }">
                            <v-text-field
                                v-if="new_stocks[getIndex(item.id)]"
                                v-model="new_stocks[getIndex(item.id)].new_stock"
                                type="number"
                                outlined
                                dense
                                hide-details
                                class="input-stock float-right"
                            ></v-text-field>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-btn
                            dark
                            color="blue"
                            small
                            class="mr-3"
                            @click="handleViewKardex(item)"
                            >
                            Kardex
                            </v-btn>

                            <!--<v-btn
                            dark
                            color="grey"
                            small
                            @click="handleViewAvailability(item)"
                            class="mr-3"
                            >
                            Ver stock
                            </v-btn>-->
                        </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>

            <v-row v-else>
                <v-col cols="12" class="pt-1 pb-1">
                <v-card>
                    <v-data-table
                    v-model="selectedRows"
                    :headers="headers_office"
                    :items="insumos"
                    :items-per-page="10"
                    class="elevation-1 w-table"
                    height="50vh"
                    :single-select="true"
                    :loading="loading"
                    :options.sync="options"
                    loading-text="Loading... Please wait"
                    >
                    >
                    <template v-slot:top>
                        <span
                        class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table"
                        >
                        Inventario
                        </span>
                        <v-divider></v-divider>
                    </template>

                    <template v-slot:item.description="{ item }">
                        <span>
                        {{ item.product.name }}
                        </span>
                    </template>

                    <template v-slot:item.category_id="{ item }">
                            <span v-if="item.category">{{ item.category.name }}</span>

                        </template>

                        <template v-slot:item.unit_id="{ item }">
                            <span v-if="item.unit">{{ item.unit.name }}</span>
                        </template>


                    <template v-slot:item.stock="{ item }">
                        <span
                        v-if="
                            item.stock 
                        "
                        >
                        {{ item.stock }}
                        </span>
                        <span
                        v-else-if="
                            item.stock &&
                            parseFloat(item.stock) <= parseFloat(item.minimum_stock)
                        "
                        class="label-minimum_stock"
                        >
                        <v-icon color="red" class="mr-2" size="16">
                            fas fa-exclamation-triangle
                        </v-icon>
                        {{ item.stock }}
                        </span>
                        <span v-else class="label-minimum_stock">
                        <v-icon color="red" class="mr-2" size="16">
                            fas fa-exclamation-triangle
                        </v-icon>
                        0.00
                        </span>
                    </template>

                    <template v-slot:item.cost="{ item }">
                        <span v-if="item.cost">
                        {{ (item.stock * parseFloat(item.cost)) | currency("S/") }}
                        </span>
                    </template>

                    <template v-slot:item.new_stock="{ item }">
                                                <v-text-field 
                                                v-if="new_stocks[getIndex(item.id)]"
                                                    type="number"
                                                    v-model="new_stocks[getIndex(item.id)].new_stock"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    class="input-stock float-right"
                                                ></v-text-field>
                                            </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn
                        dark
                        color="blue"
                        small
                        class="mr-3"
                        @click="handleViewKardex(item)"
                        >
                        Kardex
                        </v-btn>

                        <!--<v-btn
                        dark
                        color="green"
                        small
                        @click="handleViewConvertProduct(item)"
                        class="mr-3"
                        >
                        Convertir
                        </v-btn>

                        <v-btn
                        dark
                        color="grey"
                        small
                        @click="handleViewAvailability(item)"
                        class="mr-3"
                        >
                        Ver stock
                        </v-btn>-->
                    </template>
                    </v-data-table>
                </v-card>
                </v-col>
            </v-row>
      
        </template>

        <FiltersPaginated ref="filters"></FiltersPaginated>

        <stock-stores ref="stockStores"></stock-stores>

        <convert-product ref="convertProduct"></convert-product>

        <download-inventory ref="downloadInventory"></download-inventory>

        <areas-dialog ref="areasDialog"></areas-dialog>
    </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import router from "@/router/index";
import OfficeLogin from "../../../components/auth/OfficeLogin";
import ArrayTools from "../../../helpers/ArrayTools";
import FiltersPaginated from "../../../components/storehouses/FiltersPaginated";
import StorehouseBreadcrumb from "../../../components/storehouses/StorehouseBreadcrumb";
import StockStores from "../../../components/storehouses/StockStores";
import ConvertProduct from "../../../components/storehouses/ConvertProduct";
import DownloadInventory from "../../../components/storehouses/DownloadInventory";
import AreasDialog from "../../../components/storehouses/AreasDialog";
import InventoryInsumosApi from '../../../apis/InventoriInsumos';
import ProductionApi from '../../../apis/Production';

export default {
    name: "StorehousesInsumo",
    components: {
        FiltersPaginated,
        StorehouseBreadcrumb,
        StockStores,
        ConvertProduct,
        OfficeLogin,
        DownloadInventory,
        AreasDialog,
    },
    watch: {
        options: {
            handler() {
                this.loading = true;
                console.log(this.options);
                this.perPage = this.options.itemsPerPage;
                this.pageSelected = this.options.page;
                const search = this.search_product;
                console.log(search);
                if (search != null && search.trim().length > 0) {
                    ProductionApi.get_all({
                        paginated: true,
                        page: this.pageSelected,
                        itemsPerPage: this.perPage,
                        name: search//this.search_product,
                    }).then((res) => (this.loading = false))
                    .catch((err) => (this.loading = false));
                } else {
                    ProductionApi.get_all({
                        paginated: true,
                        page: this.pageSelected,
                        itemsPerPage: this.perPage,
                        //name: search//this.search_product,
                    }).then((res) => (this.loading = false))
                    .catch((err) => (this.loading = false));
                }
            },
            deep: true,
        }
    },
    data() {
        return {
            itemsBreadcrumbs: [
                {
                    text: "Logística",
                    disabled: true,
                    href: "",
                },
            ],
            companies: [],
            value_company: null,
            disabled_company: false,
            sedes: [],
            value_sede: null,
            disabled_sede: false,
            offices: [],
            value_office: null,
            headers: [],
            search_product: '',
            headers_office: [
                {
                    text: "DESCRIPCIÓN",
                    value: "name",
                    sortable: false,
                    width: 450,
                },
                { text: "CATEGORIA", value: "category_id", sortable: false },
                { text: "UND. DE MEDIDA", value: "unit_id", sortable: false },
                { text: "STOCK", value: "stock", align: "end", sortable: false },
                { text: "VALORIZADO", value: "cost", align: "end", sortable: false },
                {
                    text: "CANT. MOVIMIENTO",
                    value: "new_stock",
                    align: "center",
                    width: 130,
                    sortable: false,
                },
                { text: "", value: "actions", align: "end", sortable: false },
            ],
            headers_main: [
                {
                    text: "DESCRIPCIÓN",
                    value: "name",
                    sortable: false,
                    width: 450,
                },
                { text: "CATEGORIA", value: "category_name", sortable: false },
                { text: "UND. DE MEDIDA", value: "unit_name", sortable: false },
                { text: "STOCK", value: "stock", align: "end", sortable: false },
                { text: "VALORIZADO", value: "cost", align: "end", sortable: false },
                {
                    text: "CANT. MOVIMIENTO",
                    value: "new_stock",
                    align: "center",
                    width: 130,
                    sortable: false,
                },
                { text: "", value: "actions", align: "end" },
            ],
            products: [],
            selectedRows: [],
            search_inventory: null,
            new_stocks: [],
            loading: false,
            dataTable: { page: 1 },
            options: {},
            pageSelected: 1,
            perPage: 10,
            insumos: []
        };
    },
    mounted() {
        
    },
    computed: {
        showButtonSave() {
            let hideButton = true
            let numberItemsModified = 0
            this.new_stocks.forEach((element)=>{
                console.log(element);
                if (parseFloat(element.new_stock) > 0) {
                    numberItemsModified++
                }
                console.log(numberItemsModified);
            })
            if (numberItemsModified > 0) {
                hideButton = false
            }
            return hideButton
        },
    },
    methods: {
        ...mapActions("inventories", [
            "clean_data",
            "getInventories",
            "run_select_inventory",
            "run_set_main_storehouse",
            "run_set_office_id",
            "getList",
            "getAvailability",
            "selectOfficeFiltered",
            "storeNewStock",
            "setInventories",
            "storeDomesticConsumption",
            "storeDecrease",
            "setInventorySelected",
            "replaceInventoryItem",
            "increaseStock",
            "changeFilterPaginated",
        ]),
        ...mapActions('notifications', [
            'store_notification'
        ]),
        handleViewKardex(item) {
            console.log(item);
            this.run_select_inventory(item);
            router.push("/app/logistica/kardex-insumos");
        },
        fillNewStock(){
            this.new_stocks = []
            this.insumos.forEach((element) => {
                let item = {
                    supply_id: element.id,
                    new_stock: null
                }
                this.new_stocks.push(item)
            });
        },
        getIndex(supply_id) {
            return this.new_stocks.findIndex(i => i.supply_id === supply_id)
        },
        showOptionCrud() {
            this.show_options_crud = this.selectedRows.length > 0;
            console.log(this.show_options_crud);
            if (this.show_options_crud) {
                //let item = item.offices && item.offices.length == 1 && item.offices[0].active
                let item = this.selectedRows[0];
                console.log(item);
                //if (item.offices && item.offices.length == 1) {
                this.show_btn_active = item.status;
                console.log(this.show_btn_active);
                //}
                //this.show_btn_active = !this.selectedRows[0].active
            }
        },
        handleInputSearchByEnter(KeyboardEvent) {
            if (KeyboardEvent.code == "Enter") {
                this.handleSearch();
            }
        },
        async handleSearch() {
            this.selectedRows = [];
            /*this.changeFilterPaginated({
                name: null,
                sku: null,
                barcode: null,
                category_id: null,
                brand_id: null,
                unit_id: null,
                office_id: null,
            });*/
            this.showOptionCrud();
            this.loading = true;
            let filters = {};
            this.dataTable.page = 1;
            if (this.search_product != undefined) {
                console.log('el search tiene algo');
                console.log(this.search_product);
                if (this.search_product.trim().length > 0) {
                    filters = {
                        name: this.search_product,
                        office_id: this.office_id,
                    };
                } else {
                        filters = {
                        paginated: true,
                        page: this.pageSelected,
                        itemsPerPage: this.perPage,
                    };
                }
            } else {
                console.log('el search no tiene algo');
                console.log(this.search_product);
                filters = {
                        paginated: true,
                        page: this.pageSelected,
                        itemsPerPage: this.perPage,
                    };
            }
            
            await ProductionApi.get_all(filters)
                .then((result) => {
                    console.log('este es el resultado');
                    console.log(result);
                    this.loading = false; 
                    if (this.search_product.trim().length > 0){
                        this.insumos = result.data;
                    }else{
                        this.insumos = result.data;
                    }
                    
                })
                .catch((errr) => (this.loading = false));
        },
        handleSaveStock(typeMovement){
            let new_inventory = []
            this.new_stocks.forEach((element) => {
                if (parseFloat(element.new_stock)){
                    new_inventory.push(element)
                }
            })
            let data = {
                new_stocks: new_inventory
            }
            console.log(data);
            console.log(typeMovement);
            if (typeMovement === 'new_stock') {
                this.saveNewInventory(data);
            } else if (typeMovement === 'domestic_consumption') {
                this.saveDomesticConsumption(data);
            } else if (typeMovement === 'decrease') {
                this.saveDecrease(data);
            }
        },
        saveNewInventory (data) {
            ProductionApi.saveNewInventory(data).then((response) => {
                this.store_notification({
                    type: 'success',
                    message: 'El registro de aumento de stock se realizó de forma correcta.'
                });
                this.load();
                this.fillNewStock();
                
            }).catch( (error) => {
                this.store_notification({
                    type: 'error',
                    message: 'Se detectó un error al guardar nuevo stock.'
                });
                
            });
        },
        saveDomesticConsumption (data) {
            ProductionApi.saveDomesticConsumption(data).then((response) => {
                this.store_notification({
                    type: 'success',
                    message: 'El consumo interno se registró con éxito.'
                });
                this.load();//this.getSupplies()
                this.fillNewStock()
            }).catch( (error) => {
                let message = 'Se detectó un error al guardar consumo interno';
                if (error.response.data && error.response.data.error_custom) {
                    message = error.response.data.error_custom;
                }
                this.store_notification({
                    type: 'error',
                    message: 'Se detectó un error al guardar consumo interno.'
                });
            });
        },
        saveDecrease (data) {
            ProductionApi.saveDecrease(data).then((response) => {
                this.store_notification({
                    type: 'success',
                    message: 'La merma se registró con éxito.'
                });
                this.load();//this.getSupplies()
                this.fillNewStock()
            }).catch( (error) => {
                let message = 'Se detectó un error al guardar merma';
                if (error.response.data && error.response.data.error_custom) {
                    message = error.response.data.error_custom;
                }
                this.store_notification({
                    type: 'error',
                    message: 'Se detectó un error al guardar merma.'
                });
            });
        },
        load() {
            ProductionApi.get_all({
                paginated: true,
                page: this.pageSelected,
                itemsPerPage: this.perPage,
                name: this.search_product,
            }).then((value) => {
                console.log(value.data);
                console.log(value.data.data);
                this.insumos = value.data;
                this.fillNewStock();
            });
        }
    },
    created() {
        this.load();
    },
    destroyed() {
        this.setInventories([]);
    },
    
}
</script>

<style>
</style>